<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="cvh5()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://store.metcal.com/INTERSHOP/static/WFS/OKI-METCAL-Site/-/OKI/en_US/L/CV-H5-DS.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">CV-H5-DS</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="mx2550()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%; margin: auto"
                class="card-img-top img-fluid"
                src="https://store.metcal.com/INTERSHOP/static/WFS/OKI-METCAL-Site/-/OKI/en_US/L/MX-5250.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">MX-5250
</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="mfr1150()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%; margin: auto"
                class="card-img-top img-fluid"
                src="https://store.metcal.com/INTERSHOP/static/WFS/OKI-METCAL-Site/-/OKI/en_US/L/MFR-1150.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">MFR-1150</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="mfr1350()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%; margin: auto"
                class="card-img-top img-fluid"
                src="https://store.metcal.com/INTERSHOP/static/WFS/OKI-METCAL-Site/-/OKI/en_US/L/MFR-1350.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">MFR-1350</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    cvh5() {
      window.location.replace(
        "https://store.metcal.com/en-us/shop/soldering-desoldering/hand-pieces/CV-H5-DS"
      );
    },
    mx2550() {
      window.location.replace(
        "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/mx-series/MX-5250"
      );
    },
    mfr1150() {
      window.location.replace(
        "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/mfr-series/MFR-1150"
      );
      
    },
    mfr1350() {
      window.location.replace(
        "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/mfr-series/MFR-1350"
      );
    },
  },
};
</script>